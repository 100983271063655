import React, { createContext } from "react";
import { get } from "lodash-es";

const { Provider, Consumer } = createContext({});

interface IState {
  locale: string;
  setLocale: (locale: React.SyntheticEvent) => void;
  texts: {};
  err: string;
  translate: (keyword: string) => string;
}

class TranslationProvider extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      locale: "",
      setLocale: this.setLocale,
      texts: {},
      translate: this.translate,
      err: "Error Translation"
    };
  }

  componentDidMount() {
    const locale =
      window.localStorage.getItem("lang") === ("ru-RU" || "en-GB")
        ? window.localStorage.getItem("lang")
        : "en-GB";
    this.setLocale(locale);
  }

  translate = (keyword: string): string => {
    return get(this.state.texts, keyword, "");
  };

  setLocale = (locale: any) => {
    if (this.state.locale !== locale) {
      this.setState({ locale });
      window.localStorage.setItem("lang", locale);
      import(`../locale/${locale}`).then(module => {
        this.setState({ texts: module.default });
      });
    }
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { TranslationProvider, Consumer as TranslationConsumer };
