import * as React from "react";
import "../styles/footer.css";
import { TranslationConsumer } from "../context/TranslationContext";

export interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <footer id="footer">
      <TranslationConsumer>
        {(context: any) => {
          return (
            <>
              <div className="top-footer">
                <div className="container">
                  <div className="footer-contents">
                    <div className="footer-about">
                      <h2>{context.translate("footer.about")}</h2>
                      <hr />
                      <span>{context.translate("footer.aboutText")}</span>
                    </div>
                    <div>
                      <h2>{context.translate("header.contactUs")}</h2>
                      <hr />
                      <ul>
                        <li className="address">
                          <strong><a target="blank" href="https://goo.gl/maps/X6ESa4xnbwJ2">{context.translate("footer.address")}</a></strong>
                        </li>
                        <li className="tel">
                          <strong>
                            <a href="tel:+374 11 77 77 30">
                              {context.translate("footer.tel1")}
                            </a>
                          </strong>
                          <br />
                          <a href="tel:+374 55 77 77 84">
                            <strong>{context.translate("footer.tel2")}</strong>
                          </a>
                        </li>
                        <li className="mail">
                          <strong>
                            <a href="mailto:info@closures.am">
                              {context.translate("footer.mail1")}
                            </a>
                          </strong>
                          <strong>
                            <a href="mailto:uclosures@gmail.com">
                              {context.translate("footer.mail2")}
                            </a>
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-footer">
                <div className="container">
                  <strong>
                    &copy; {context.translate("footer.copyright")}
                  </strong>
                </div>
              </div>
            </>
          );
        }}
      </TranslationConsumer>
    </footer>
  );
};

export default Footer;
