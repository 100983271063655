import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import Footer from "./footer";
import NavBar from "./NavBar";
import { TranslationProvider } from "../context/TranslationContext";

const Video = require("../video/UC.mp4");

interface IProps { }

const Layout: React.FunctionComponent<IProps> = ({ children }) => {
  let [showVideo, setShowVideo] = React.useState<boolean>(true);
  React.useEffect(() => {
    (document.querySelector("video") as HTMLVideoElement).playbackRate = 3;
    (document.querySelector("video") as HTMLVideoElement).play();
    setTimeout(() => setShowVideo(false), 2500);
  }, []);
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data =>
        showVideo ? (
          <>
            <head>
              <meta name="yandex-verification" content="c26ca41bc6ff00b5" />
            </head>
            <video muted preload="metadata">
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          </>
        ) : (
            <TranslationProvider>
              <NavBar />
              {children}
              <Footer />
            </TranslationProvider>
          )
      }
    />
  );
};

export default Layout;
