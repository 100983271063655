import * as React from "react";
import { TranslationConsumer } from "../context/TranslationContext";
import styled from "styled-components";
import Ru from "../images/russia.svg";
import En from "../images/united-kingdom.svg";
import logo from "../images/logo.png";

import { Spring } from "react-spring/renderprops";

export interface NavProps {
  toggleShow?: () => void;
  toggleList?: boolean;
}

export interface NavState {
  show: boolean;
  hamburger: boolean;
}

const HeaderContainer = styled.div`
  .flags {
    display: flex;
    justify-content: center;
  }

  a:focus {
    outline: none;
  }

  .flags img {
    width: 20px;
  }

  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  .logo-class {
    width: 60px;
  }
`;

class Nav extends React.Component<NavProps, NavState> {
  state = {
    show: false,
    hamburger: false
  };

  toggleShow = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({
      show: !this.state.show
    });
  };

  onResize = (e: Event): void => {
    const isSmall = window.innerWidth < 900;
    this.setState(state =>
      isSmall === state.hamburger
        ? null
        : {
          hamburger: isSmall,
          show: !isSmall
        }
    );
  };

  navigateTo = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { href } = e.currentTarget;
    e.preventDefault();
    window.scroll({
      top:
        (document.querySelector(
          href.slice(href.lastIndexOf("/") + 1)
        ) as HTMLElement).offsetTop - 88,
      behavior: "smooth"
    });
    this.setState(state =>
      state.hamburger && state.show ? { show: false } : null
    );
  };

  componentDidMount() {
    this.setState({
      show: window.innerWidth >= 900,
      hamburger: window.innerWidth < 900
    });
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  render() {
    const { show, hamburger } = this.state;
    return (
      <header className="head">
        <HeaderContainer>
          <div className="logo-class">
            <a href="#___gatsby" onClick={this.navigateTo}>
              <img src={logo} alt="UC" />
            </a>
          </div>
          {hamburger ? (
            <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
              {styles => (
                <button onClick={this.toggleShow} style={styles}>
                  <i className="icon-menu-bars" />
                </button>
              )}
            </Spring>
          ) : null}
          {show ? (
            <TranslationConsumer>
              {(context: any) => {
                return (
                  <Spring
                    from={
                      hamburger
                        ? { transform: "translate(100%)" }
                        : { opacity: 0 }
                    }
                    to={
                      hamburger
                        ? { transform: "translate(0px)" }
                        : { opacity: 1 }
                    }
                  >
                    {styles => (
                      <ul
                        className={hamburger ? "hamburger" : ""}
                        style={styles}
                      >
                        <li>
                          <a href="#machinery" onClick={this.navigateTo}>
                            {context.translate("header.machinery")}
                          </a>
                        </li>
                        <li>
                          <a href="#mission" onClick={this.navigateTo}>
                            {context.translate("header.mission")}
                          </a>
                        </li>
                        <li>
                          <a href="#footer" onClick={this.navigateTo}>
                            {context.translate("header.contactUs")}
                          </a>
                        </li>
                        <li className="flags">
                          <a
                            style={{ marginRight: "5px" }}
                            href="javasricpt:void"
                            onClick={e => {
                              context.setLocale("ru-RU");
                              this.setState(state =>
                                state.hamburger && state.show ? { show: false } : null
                              );
                            }}
                          >
                            <img src={Ru} alt="Russian" />
                          </a>
                          <a
                            style={{ marginLeft: "5px" }}
                            href="javasricpt:void"
                            onClick={e => {
                              context.setLocale("en-GB");
                              this.setState(state =>
                                state.hamburger && state.show ? { show: false } : null
                              );
                            }
                            }
                          >
                            <img src={En} alt="English" />
                          </a>
                        </li>
                      </ul>
                    )}
                  </Spring>
                );
              }}
            </TranslationConsumer>
          ) : null}
        </HeaderContainer>
      </header>
    );
  }
}

export default Nav;
